import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const FourOhFourWrapper = styled.div`
  text-align: center;
  margin: 60px auto;

  > h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <FourOhFourWrapper>
      <h1>
        <FormattedMessage id="404" />
      </h1>
    </FourOhFourWrapper>
  </Layout>
)

export default NotFoundPage
